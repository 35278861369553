import React, {useState} from 'react'
import FormActionButton from '../../../../shared/FormActionButton'
import {
  faSave,
  faStop,
  faPlane,
  faCheck,
  faEnvelope,
  faClipboard,
  faMailReplyAll
} from '@fortawesome/free-solid-svg-icons'
import simpleRequest from '../../../../utils/simpleRequest'
import {requirementRelease} from '../../../../routes/requirements'
import Swal from 'sweetalert2'
import HoldBackModal from './HoldBackModal'
import AuditorsModal from './AuditorsModal'
import LettersModal from './LettersModal';

const RequirementFormFooter = (
  {
    requirement,
    updateRequirement,
    setShowBackgroundsModal,
    holdBackRequirementCallback,
    saveRequirementCallback,
    finishRequirementCallback,
    auditRequirementCallback,
    isEditable,
    letterTypes
  }
) => {

  const requirementType = window.isBudget ? 'budget' : 'expense'
  const [showHoldBackModal, setShowHoldBackModal] = useState(false)
  const [showAuditorsModal, setShowAuditorsModal] = useState(false)
  const [showLettersModal, setShowLettersModal] = useState(false);

  const getRequirementType = () => {
    return requirementType === 'budget' ? 'presupuesto' : 'gasto'
  }

  const handleSaveBtnClick = () => {
    // Guardamos cualquier cambio
    saveRequirementCallback()
  }

  const handleHoldBackBtnClick = () => {
    // Abrimos modal de comentario de retención
    setShowHoldBackModal(true)
  }

  const handleReleaseBtnClick = () => {
    // Confirmamos y redirigimos
    Swal.fire({
      title: '¿Seguro desea continuar?',
      text: 'Se perderán los cambios no guardados',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      icon: 'question'
    }).then((result) => {
      if (result.isConfirmed) {
        let formData =  new FormData(),
          successText = 'Operación realizada con exito. Espere unos momentos mientras es redirigido.',
          errorText = 'Ocurrió un error inesperado. Intente nuevamente'
        simpleRequest(formData, requirementRelease, successText, errorText)
      }
    })
  }

  const handleEditBtnClick = () => {
    const insurerId = requirement.insurerId;
    const requirementId = requirement.id;
    window.location.href = `/insurers/${insurerId}/expenses/${requirementId}/edit2`;
  };

  const handleAuditoryBtnClick = () => {
    // Guardamos cualquier cambio
    setShowAuditorsModal(true)
  }

  const handleApproveBtnClick = () => {
    // Guardamos cualquier cambio
    finishRequirementCallback()
  }

  const handleRejectBackgroundBtnClick = () => {
    // Mostramos modal para ingresar comentario y antecedentes
    setShowBackgroundsModal(true)
  }

  const handleLettersBtnClick = () => {
    setShowLettersModal(true);
  };

  return (
    <>
      {!requirement.forbiddenPolicy && (
        <div className={'form-action-buttons d-flex justify-content-center align-items-center w-100 border border-white rounded p-3 bg-white mt-3'}>
          {isEditable ? (
            <>
              {(requirement.canHoldBack && !requirement.requiredAudit) && (
                <>
                  <FormActionButton
                    title={'Retener ' + getRequirementType()}
                    className={'btn-primary'}
                    onClick={handleHoldBackBtnClick}
                    content={'Retener ' + getRequirementType()}
                    icon={faStop}
                  />
                  <HoldBackModal
                    {...
                      {
                        requirement,
                        updateRequirement,
                        showHoldBackModal,
                        setShowHoldBackModal,
                        holdBackRequirementCallback
                      }
                    }
                  />
                </>
              )}
              {(requirement.canRelease && !requirement.requiredAudit) && (
                <FormActionButton
                  title={'Liberar ' + getRequirementType()}
                  className={'btn-danger'}
                  onClick={handleReleaseBtnClick}
                  content={'Liberar ' + getRequirementType()}
                  icon={faPlane}
                />
              )}
              <FormActionButton
                title={'Guardar'}
                className={'btn-info'}
                onClick={handleSaveBtnClick}
                content={'Guardar'}
                icon={faSave}
              />
              {(requirement.showApproveBtn && !requirement.requiredAudit) && (
                <FormActionButton
                  title={requirement.canApprove ? 'Aprobar' : 'Solicitar aprobación'}
                  className={'btn-primary' + (requirement.unlockApprove ? ' unlockable' : '')}
                  onClick={handleApproveBtnClick}
                  content={'Aprobar'}
                  icon={faCheck}
                />
              )}
              {!requirement.requiredAudit && (
                <FormActionButton
                  title={'Rechazo total: Falta de Antecedentes'}
                  className={'btn-danger'}
                  onClick={handleRejectBackgroundBtnClick}
                  content={'Falta de Antecedentes'}
                  icon={faMailReplyAll}
                />
              )}
              {(requirement.canApprove || requirement.requiredAudit) && (
                <FormActionButton
                  title={'Envíar a contraloría'}
                  className={'btn-light'}
                  onClick={handleAuditoryBtnClick}
                  content={'Contraloría'}
                  icon={faClipboard}
                />
              )}
              <AuditorsModal
                {...
                  {
                    requirement,
                    updateRequirement,
                    showAuditorsModal,
                    setShowAuditorsModal,
                    auditRequirementCallback
                  }
                }
              />
            </>
            ) : (
            <>
              <FormActionButton
                title={'Cartas de Aseguradora'}
                className={'btn-light'}
                onClick={handleLettersBtnClick}
                content={'Cartas'}
                icon={faEnvelope}
              />
              <LettersModal
                {...
                  {
                    showLettersModal,
                    setShowLettersModal,
                    letterTypes,
                    requirement
                  }
                }
              />
              <FormActionButton
                title={'Editar'}
                className={'btn-primary'}
                onClick={handleEditBtnClick}
                content={'Editar Gasto'}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RequirementFormFooter