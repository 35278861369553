import React, {useState} from 'react'
import ProvisionDetailRejectsModal from './ProvisionDetailRejectsModal'
import PrescriptionsModal from './prescriptions/PrescriptionsModal'
import NotPerformedRejectModal from './NotPerformedRejectModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faThumbsDown,
  faPills,
  faComments,
  faHouseMedicalCircleXmark
} from '@fortawesome/free-solid-svg-icons'

const ProvisionDetailRejects = (
  {
    provisionDetail,
    setProvisionDetail,
    index,
    prescriptions,
    setPrescriptions,
    currency,
    setShowProvisionDetailCommentsModal,
    attachments,
    policyId,
    insuree,
    policy,
    isEditable
  }
) => {
  const [showProvisionDetailPrescriptionsModal, setShowProvisionDetailPrescriptionsModal] = useState(false)
  const [showProvisionDetailRejectsModal, setShowProvisionDetailRejectsModal] = useState(false)
  const [showNotPerformedRejectModal, setShowNotPerformedRejectModal] = useState(false)

  const handleRejectsBtnClick = () => {
    setShowProvisionDetailRejectsModal(true)
  }

  const handlePrescriptionsBtnClick = () => {
    setShowProvisionDetailPrescriptionsModal(true)
  }


  const handleCommentsBtnClick = () => {
    setShowProvisionDetailCommentsModal(true)
  }

  const handleNotPerformedRejectBtnClick = () => {
    setShowNotPerformedRejectModal(true)
  }

  const handleProvisionDetailRejectsModalConfirmButtonClick = () => {
    // Enviamos a evaluar de nuevo el documento con los nuevos rechazos activos

    // Luego cerramos el modal
    setShowProvisionDetailRejectsModal(false)
  }

  const handleNotPerformedRejectModalConfirmButtonClick = () => {
    // Creamos el rechazo 26 dentro de los rejectDetails
    // Luego cerramos el modal
    setShowNotPerformedRejectModal(false)
  }

  return (
    <div className={'d-flex justify-content-center'}>
      <div
        className={'btn btn-light mr-1'}
        onClick={handleRejectsBtnClick}
        title={'Rechazos'}
      >
        <FontAwesomeIcon icon={faThumbsDown}/> ({provisionDetail.rejectDetails?.length})
      </div>
      <div
        className={'btn btn-light' + (provisionDetail.showCommentsSection ? ' mr-1' : '')}
        onClick={handlePrescriptionsBtnClick}
        title={'Recetas'}
      >
        <FontAwesomeIcon icon={faPills}/> ({prescriptions.length})
      </div>
      <div
        className={'btn btn-danger ml-1'}
        onClick={handleNotPerformedRejectBtnClick}
        title={'Rechazar por tratamiento no realizado'}
      >
        <FontAwesomeIcon icon={faHouseMedicalCircleXmark}/>
      </div>
      {provisionDetail.showCommentsSection && (
        <>
          <div
            className={'btn btn-light'}
            onClick={handleCommentsBtnClick}
            title={'Comentarios'}
          >
            <FontAwesomeIcon icon={faComments}/> ({provisionDetail.commentsLength})
          </div>
        </>
      )}
      {showProvisionDetailRejectsModal && (
        <ProvisionDetailRejectsModal
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              showProvisionDetailRejectsModal,
              setShowProvisionDetailRejectsModal,
              currency,
              isEditable
            }
          }
          handleConfirm={handleProvisionDetailRejectsModalConfirmButtonClick}
        />
      )}
      {showProvisionDetailPrescriptionsModal && (
        <PrescriptionsModal
          {...
            {
              provisionDetail,
              setProvisionDetail,
              index,
              showProvisionDetailPrescriptionsModal,
              setShowProvisionDetailPrescriptionsModal,
              prescriptions,
              setPrescriptions,
              attachments,
              policyId,
              insuree,
              policy,
              isEditable
            }
          }
        />
      )}
      {showNotPerformedRejectModal && (
        <NotPerformedRejectModal
          {...
            {
               provisionDetail,
               setProvisionDetail,
               index,
               showNotPerformedRejectModal,
               setShowNotPerformedRejectModal,
               currency
            }
          }
          handleConfirm={handleNotPerformedRejectModalConfirmButtonClick}
        />
      )}
    </div>
  )
}

export default ProvisionDetailRejects