import React from 'react'
import DocumentBodyProvisionDetail from './DocumentBodyProvisionDetail'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const DocumentBodyProvisionDetails = (
  {
    provisionDetails,
    setProvisionDetail,
    removeProvisionDetail,
    currency,
    prescriptions,
    setPrescriptions,
    attachments,
    healthProvisionTypes,
    providerId,
    policyInstance,
    directProblemProviderReject,
    policyId,
    insuree,
    policy,
    requirementType,
    toothOptions,
    sideOptions,
    isEditable
  }
) => {

  const tariffLimitFactorTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Factor tope de arancel
    </Tooltip>
  );

  // Total solicitado es solo para dental, pero podría ser para ambos
  return (
    <>
      {provisionDetails.length > 0 && (
        <div className={'provision-details-table'}>
          <table className={'table table-bordered table-hover'}>
            <thead>
              <tr className={'table-active'}>
                <th scope={'col'}></th>
                <th className={'provision-details-table__col provision-details-table__col--provision'} scope={'col'}>Prestación</th>
                {requirementType == "health" && <th className={'provision-details-table__col provision-details-table__col--health-provision-type'} scope={'col'}>Tipo de<br></br>prestación</th>}
                {requirementType == "dental" && <th className={'provision-details-table__col provision-details-table__col--dental-fields'} scope={'col'}>Pieza</th>}
                {requirementType == "dental" && <th className={'provision-details-table__col provision-details-table__col--dental-fields'} scope={'col'}>Caras</th>}
                {requirementType == "dental" && <th className={'provision-details-table__col provision-details-table__col--date-input'} scope={'col'}>Fecha de atención</th>}
                {requirementType == "dental" && <th className={'provision-details-table__col'} scope={'col'}>
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 300 }}
                    overlay={tariffLimitFactorTooltip}
                  >
                    <a>%</a>
                  </OverlayTrigger></th>}
                {requirementType == "dental" && <th className={'provision-details-table__col'} scope={'col'}>Valor de referencia</th>}
                <th className={'provision-details-table__col'} scope={'col'}>Valor Total</th>
                <th className={'provision-details-table__col'} scope={'col'}>Bonificación<br></br>Isapre</th>
                {requirementType == "health" && <th className={'provision-details-table__col'} scope={'col'}>Descuento<br></br>otros seguros</th>}
                <th className={'provision-details-table__col'} scope={'col'}>{requirementType == "health" ? 'Valor' : 'Total'}<br></br>solicitado</th>
                <th className={'provision-details-table__col'} scope={'col'}>Cantidad<br></br>solicitada</th>
                <th className={'provision-details-table__col'} scope={'col'}>Cantidad<br></br>aprobada</th>
                <th className={'provision-details-table__col'} scope={'col'}>Valor<br></br>aprobado</th>
                {requirementType == "health" && <th className={'provision-details-table__col'} scope={'col'}>%<br></br>cobertura</th>}
                <th className={'provision-details-table__col'} scope={'col'}>Valor<br></br>reembolso</th>
                <th className={'provision-details-table__col provision-details-table__col--rejects'} scope={'col'}>Rechazos y<br></br>antecedentes</th>
              </tr>
            </thead>
            <tbody>
            {provisionDetails.map((provisionDetail) => (
              <DocumentBodyProvisionDetail
                {...
                  {
                    provisionDetail,
                    setProvisionDetail,
                    removeProvisionDetail,
                    currency,
                    prescriptions,
                    setPrescriptions,
                    attachments,
                    healthProvisionTypes,
                    providerId,
                    policyInstance,
                    directProblemProviderReject,
                    policyId,
                    insuree,
                    policy,
                    requirementType,
                    toothOptions,
                    sideOptions,
                    isEditable
                  }
                }
                index={provisionDetail.systemId}
                key={provisionDetail.key}
              />
            ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default DocumentBodyProvisionDetails