import React, {useEffect, useState} from 'react'
import RequirePreapproval from './RequirePreapproval'
import PolicyAndInsuree from './policy_and_insuree'
import closeAlert from '../../../../utils/closeAlert'
import errorSwalAlert from '../../../../utils/errorSwalAlert'
import showLoading from '../../../../utils/showLoading'
import Files from './files'
import StepContainer from '../../../../shared/StepContainer'
import HeaderFormActionButtons from './action_buttons'
import axios from 'axios'
import {
  requirementRejectByBackgrounds,
  requirementSaveHeader
} from '../../../../routes/requirements'
import CompanyInfo from './company_info'
import FileActionButtons from './files/FileActionButtons'
import alertWhileRedirect from '../../../../utils/alertWhileRedirect'
import buildHeader from '../../../../utils/buildHeader'
import blockedByFormHeaderAlerts from '../../../../utils/blockedByFormHeaderAlerts'
import setNewWindowData from '../../../../utils/setNewWindowData'
import CardHeaderTitle from '../../../../shared/CardHeaderTitle'
import RejectDetails from './reject_details'
import rejectRequest from '../../../../utils/rejectRequest'
import PayTo from './PayTo'
import Shipment from '../../../../models/Shipment'

const RequirementFormHeader = (
  {
    requirement,
    updateRequirement,
    formAlerts,
    setFormAlerts,
    setShowHealthForm,
    setShowDental,
    setShowDentalV2,
    protectedFiles,
    setProtectedFiles,
    comments,
    setComments,
    backgrounds,
    setShowBackgroundsModal,
    removedBackgrounds,
    sendFinishBackground,
    setSendFinishBackground,
    shipment,
    setShipment,
    shipmentCode,
    setShipmentCode,
    startDateShipment,
    setStartDateShipment,
    externalCode,
    setExternalCode,
    rejectDetails,
    setRejectDetails,
    payTo,
    setPayTo,
    setInsuree,
    setPolicy,
    insurerDentalV2Enabled,
    isEditable
  }
) => {

  const insurerId = window.insurerId
  const requirementType = window.isBudget ? 'budget' : 'expense'


  const [liquidator, _setLiquidator] = useState(requirement.liquidator)
  const [policyId, _setPolicyId] = useState(requirement.policy_id)
  const [insureeId, _setInsureeId] = useState(requirement.insuree_id)
  const [showRejectButton, _setShowRejectButton] = useState(requirement.showRejectButton)
  const [reentryCode, _setReentryCode] = useState(requirement.reentryCode)
  const [oldReferenceId, _setOldReferenceId] = useState(requirement.old_reference_id)
  const [principalDni, _setPrincipalDni] = useState(requirement.principalDni)
  const principalDniType = requirement.principalDniType
  const [principalId, _setPrincipalId] = useState(requirement.principalId)
  const [requirePreapproval, _setRequirePreapproval] = useState(requirement.requirePreapproval)
  const [allowedInsurances, setAllowedInsurances] = useState([])
  const countryIso = requirement.countryIso;

  const setLiquidator = (value) => {
    _setLiquidator(value)
    updateRequirement(['liquidator'], [value])
  }
  const setPolicyId = (value) => {
    _setPolicyId(value)
    updateRequirement(['policy_id'], [value])
  }
  const setInsureeId = (value) => {
    _setInsureeId(value)
    updateRequirement(['insuree_id'], [value])
  }
  const setShowRejectButton = (value) => {
    _setShowRejectButton(value)
    updateRequirement(['showRejectButton'], [value])
  }
  const setReentryCode = (value) => {
    _setReentryCode(value)
    updateRequirement(['reentryCode'], [value])
  }
  const setOldReferenceId = (value) => {
    _setOldReferenceId(value)
    updateRequirement(['old_reference_id'], [value])
  }
  const setPrincipalDni = (value) => {
    _setPrincipalDni(value);
    updateRequirement(['principalDni'], [value]);
  };

  const setPrincipalId = (value) => {
    _setPrincipalId(value)
    updateRequirement(['principalId'], [value])
  }

  const setRequirePreapproval = (value) => {
    _setRequirePreapproval(value)
    updateRequirement(['requirePreapproval'], [value])
  }

  const {
    nIRejectReasonId,
    hospitableRequirementRejectReasonId,
    precedingRejectReasonId,
    initialHospitableRejectDetails,
    initialNIRejectDetails,
    initialProtectedFiles,
    initialBackgrounds,
    initialInsuree,
    initialPolicy,
    isChilenaRojo,
    downloadConsolidatedUrl,
    hasExternalCodeGetter,
    includeLiquidator
  } = requirement

  const buildHeaderFormData = (action, reject_type, insuranceType) => {
    return buildHeader(
      requirement,
      action,
      reject_type,
      initialProtectedFiles,
      protectedFiles,
      window.requirementId,
      insurerId,
      requirementType,
      window.beginningTime,
      window.shipmentId,
      startDateShipment,
      externalCode,
      liquidator,
      oldReferenceId,
      policyId,
      insureeId,
      comments,
      initialBackgrounds,
      removedBackgrounds,
      precedingRejectReasonId,
      backgrounds,
      initialHospitableRejectDetails,
      hospitableRequirementRejectReasonId,
      initialNIRejectDetails,
      nIRejectReasonId,
      insuranceType
    )
  }

  const saveHeader = async (withLoading, redirectEditDental, successCallback, insuranceType) => {
    window.shipmentId = shipment.id
    window.shipment = shipment
    if (blockedByFormHeaderAlerts(formAlerts)) {
      return
    }
    let closeAlerts = !!withLoading
    if (!!withLoading) {
      showLoading()
    }
    await axios({
      url: requirementSaveHeader,
      method: 'POST',
      data: buildHeaderFormData('save',null, insuranceType)
    })
    .then ((res) => {
      let data = res.data
      if (!data.success) {
        closeAlerts = false
        // Error controlado desde el backend
        return errorSwalAlert(data.message)
      }else {
        setNewWindowData(data)
        let newRequirement = data.requirement,
          newExternalCode = newRequirement.external_code || '',
          newShipment = data.shipment,
          newShipmentCode = newShipment.code || '',
          systemShipment = new Shipment(newShipment)
        setExternalCode(newExternalCode)
        setShipment(systemShipment)
        setShipmentCode(newShipmentCode)
        setStartDateShipment(systemShipment.startDateShipment)
        updateRequirement(['id'], [data.requirement.id, systemShipment])
        if (successCallback instanceof Function) {
          closeAlerts = false
          return successCallback()
        }
      }
      if (redirectEditDental) {
        closeAlerts = false
        alertWhileRedirect('success', 'Todo en orden', 'Operación realizada con exito. Espere unos momentos mientras es redirigido.', data.redirect_to)
      }
    })
    .catch(err =>{
      // Error
      closeAlerts = false
      errorSwalAlert('Ocurrió un error inesperado al intentar guardar el encabezado. Intente nuevamente')
      // Para trazabilidad
      console.log(err)
    })
    .finally(() => {
      if (closeAlerts) {
        closeAlert()
        setShipment(window.shipment)
      }
    })
  }

  const rejectByBackgrounds = async () => {
    let formData = buildHeaderFormData('reject', 'backgrounds', requirement.insurance_type)
    rejectRequest(formData, requirementRejectByBackgrounds, true)
  }

  useEffect(() => {
    if (sendFinishBackground) {
      // Guardamos encabezado y Rechazamos total por falta de antecedentes
      saveHeader(true, false, rejectByBackgrounds)
        .then(() => {
          setSendFinishBackground(false)
        })
    }
  })

  return (
    <>
      <div className={'expense-form__header row'}>

        <RequirePreapproval
          {...
            {
              shipment,
              policyId,
              requirePreapproval,
              setRequirePreapproval
            }
          }
        />

        <StepContainer
          className={'expense-form__header-shipment'}
          cardHeader={<CardHeaderTitle title={'1 - Datos de la compañia'}/>}
          containerName={'company_info'}
          formAlerts={formAlerts}
        >
          <CompanyInfo
            {...
              {
                requirement,
                shipment,
                setShipment,
                formAlerts,
                setFormAlerts,
                hasExternalCodeGetter,
                liquidator,
                setLiquidator,
                includeLiquidator,
                externalCode,
                setExternalCode,
                reentryCode,
                setReentryCode,
                oldReferenceId,
                setOldReferenceId,
                startDateShipment,
                setStartDateShipment,
                shipmentCode,
                setShipmentCode,
                isEditable
              }
            }
            containerName={'company_info'}
          />
        </StepContainer>

        <StepContainer
          className={'expense-form__header-policy_and_insuree'}
          cardHeader={
          <div className={"row justify-content-between"}>
            <div className={"col-4"}>
              <CardHeaderTitle title={'2 - Asegurado y póliza'}/>
            </div>
            <div className={"col-8"}>
              <PayTo
                payTo={payTo}
                setPayTo={setPayTo}
                isEditable={isEditable}
              />
            </div>
          </div>
        }
          containerName={'policy_and_insuree'}
          formAlerts={formAlerts}
        >
          <PolicyAndInsuree
            {...
              {
                shipment,
                policyId,
                setPolicyId,
                countryIso,
                insureeId,
                setInsureeId,
                initialInsuree,
                initialPolicy,
                formAlerts,
                setFormAlerts,
                setShowRejectButton,
                principalDni,
                setPrincipalDni,
                principalDniType,
                setPrincipalId,
                setInsuree,
                setPolicy,
                setAllowedInsurances,
                isEditable
              }
            }
            containerName={'policy_and_insuree'}
          />
        </StepContainer>

        <StepContainer
          className={'expense-form__header-files'}
          cardHeader={<CardHeaderTitle title={'3 - Adjuntos'}/>}
          containerName={'files'}
          formAlerts={formAlerts}
          cardFooter={
            isEditable ? (
              <div className={'d-flex justify-content-end align-items-center w-100'}>
                <FileActionButtons {...{isChilenaRojo, downloadConsolidatedUrl, initialProtectedFiles, protectedFiles, setProtectedFiles}} />
              </div>
            ) : null
          }
        >
          <Files
            {...{
              protectedFiles,
              setProtectedFiles,
              initialProtectedFiles,
              formAlerts,
              setFormAlerts,
              isEditable,
            }}

            containerName={'files'}
          />
        </StepContainer>

        {rejectDetails.length > 0 && (
          <StepContainer
            className={'expense-form__header-reject-details'}
            cardHeader={<CardHeaderTitle title={'4 - Rechazos del ' + (window.isBudget ? 'presupuesto' : 'gasto')}/>}
            containerName={'rejectDetails'}
            formAlerts={formAlerts}
            cardFooter={<div className={'d-flex justify-content-end align-items-center w-100'}><FileActionButtons {...{isChilenaRojo, downloadConsolidatedUrl, initialProtectedFiles, protectedFiles, setProtectedFiles}} /></div>}
          >
            <RejectDetails
              {...
                {
                  rejectDetails,
                  setRejectDetails
                }
              }
            />
          </StepContainer>
        )}

      </div>
      {isEditable &&
        <HeaderFormActionButtons
          {...
            {
              showRejectButton,
              comments,
              setComments,
              formAlerts,
              setShowHealthForm,
              saveHeader,
              buildHeaderFormData,
              principalDni,
              setShowBackgroundsModal,
              setShowDental,
              setShowDentalV2,
              insurerDentalV2Enabled,
              allowedInsurances
            }
          }
        />
      }
    </>
  )
}

export default RequirementFormHeader