import React, {useEffect, useState} from 'react'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import FormAlert from '../shared/FormAlert'
import Select from '../../../../../shared/Select'
import Label from '../../../../../shared/Label'

const Policies = (
  {
    policies,
    policyId,
    setPolicyId,
    formAlerts,
    insureeId,
    setShowRejectButton,
    editable,
    setPolicy,
    setAllowedInsurances
  }
) => {

  const [policyName, setPolicyName] = useState(policyId && policyId !== '-1' && policies.length > 0 && policies.filter((policy) => parseInt(policy.value) === parseInt(policyId)).length > 0 ? policies.filter((policy) => parseInt(policy.value) === parseInt(policyId))[0].name : '')

  const getPlaceholder = () => {
    return policies.length === 0 ? 'Seleccione titular primero' : 'Seleccione una'
  }

  const handleChange = (event) => {
    let value = event.target.value,
      newPolicyName = '',
      newPolicy = null
    setPolicyId(value)
    if (value === '-1') {
      setShowRejectButton(true)
      newPolicyName = 'Otro (rechazar)'
    }else {
      newPolicy = policies.filter((policy) => parseInt(policy.value) === parseInt(value))[0]
      newPolicyName = newPolicy.name
      if (insureeId !== '-1') {
        setShowRejectButton(false)
      }
    }
    setPolicy(newPolicy)
    setAllowedInsurances([newPolicy.health_enabled ? "health" : "", newPolicy.dental_enabled ? "dental" : ""])
    setPolicyName(newPolicyName)
  }

  const getOptions = () => {
    let options = policies.map((policy) => {
      return {
        value: policy.value,
        label: policy.name
      }
    })
    if (policies.length !== 1) {
      options = [{value: '', label: getPlaceholder()}, ...options]
    }
    return options
  }

  useEffect(() => {
    if (policyId && policyId !== '-1' && policies.length > 0 && policies.filter((policy) => parseInt(policy.value) === parseInt(policyId)).length > 0) {
      let name = policies.filter((policy) => parseInt(policy.value) === parseInt(policyId))[0].name
      let policy = policies[0]
      if (policyName !== name) {
        setPolicy(policy)
        setPolicyName(name)
        setAllowedInsurances([policy.health_enabled ? "health" : "", policy.dental_enabled ? "dental" : ""])
      }
    }
  })

  return (
    <div className={'form-group col form-row justify-content-center align-items-center'}>
      <label htmlFor={'new_policy_id'} className={'col flex-grow-0'}>Póliza</label>
      {editable ? (
        <>
          <Select
            name={'new_policy_id'}
            className={'col' + getInputErrorClass(formAlerts, 'policy_id')}
            placeholder={getPlaceholder()}
            value={policyId}
            onChange={handleChange}
            options={getOptions()}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'policy_id')}
          />
        </>
      ) : (
        <>
          <Label
            className={'col'}
          >
            <strong>{policyName}</strong>
            {policyName ? 
                <a className='text-primary ml-1'
                  href={`/insurers/${window.insurerId}/policies/${policyId}`}
                  target="_blank"
                  title="Ver póliza (nueva pestaña)"
                >
                  Ver <i className="icon-external-link fas fa-external-link-alt"></i>
                </a> : <></>
            }
          </Label>
        </>
      )}
    </div>
  )
}

export default Policies