import React, {useEffect, useState} from 'react'
import numberToCurrency from '../../../../../../utils/numberToCurrency'
import Reject from '../../../../../../models/Reject'
import {
  providerRejectCode
} from '../../../../../../utils/constants'
import HealthProvisionDetail from './HealthProvisionDetail'
import DentalProvisionDetail from './DentalProvisionDetail'

const DocumentBodyProvisionDetail = (
  {
    provisionDetail,
    setProvisionDetail,
    removeProvisionDetail,
    index,
    currency,
    prescriptions,
    setPrescriptions,
    attachments,
    healthProvisionTypes,
    providerId,
    policyInstance,
    directProblemProviderReject,
    policyId,
    insuree,
    policy,
    requirementType,
    toothOptions,
    sideOptions,
    isEditable
  }
) => {

  const [showProvisionDetailCommentsModal, setShowProvisionDetailCommentsModal] = useState(false)

  const getApprovedValue = () => {
    return numberToCurrency(provisionDetail.approvedValue, currency)
  }

  const getCoveragePercentage = () => {
    return provisionDetail.coveragePercentage + ' %'
  }

  const getRefundValue = () => {
    return numberToCurrency(provisionDetail.refundValue, currency)
  }

  // Exclusivos dentales
  const getTariffLimitFactor = () => {
    return provisionDetail.tariffLimitFactor + ' %'
  }

  const getReferenceValue = () => {
    return numberToCurrency(provisionDetail.unitReferenceValue, currency)
  }

  const checkForProviderRejects = (providerId, provisionId, policyInstance , directProblemProviderReject) => {
    let reject = directProblemProviderReject // Estado inicial de rechazo en este punto
    providerId = parseInt(providerId)
    if (!reject && policyInstance.reject_other_providers) {
      // mmm... rechazar si no esta en listas blancas...
      // veamos lista blanca...
      if (policyInstance.provider_ids.filter((pi) => pi === providerId).length === 0) {
        // nop. Entonces, en algun condicionado de prestador?
        if (policyInstance.provider_clauses.filter((providerClause) => providerClause.provider_id === providerId).length === 0) {
          provisionId = parseInt(provisionId)
          if (policyInstance.mixed_clauses.filter((mixedClause) => mixedClause.provider_id === providerId && mixedClause.provision_id === provisionId).length === 0) {
            // Uf tampoco. Ya no hay escape...
            reject = true
          }
        }
      }
    }
    if (reject) {
      // Tenemos rechazo. Aplicamos si no lo tenemos ya como rechazo
      if (provisionDetail.rejectDetails.filter((rejectDetail) => rejectDetail.providerId === providerId && rejectDetail.code === providerRejectCode).length === 0) {
        let newProvisionDetail = provisionDetail,
          rejectDetails = provisionDetail.rejectDetails,
          newRejectDetail = new Reject({
            code: providerRejectCode,
            value: provisionDetail.totalAmount,
            comment: '',
            description: 'Prestador no cubierto por la póliza',
            active: true,
            providerId: providerId
          })
        newProvisionDetail.rejectDetails = [... rejectDetails.filter((rejectDetail) => rejectDetail.code !== providerRejectCode), newRejectDetail]
        setProvisionDetail(index, newProvisionDetail)
      }
    }
  }

  useEffect(() => {
    let {provisionId} = provisionDetail
    if (provisionId && policyInstance) {
      checkForProviderRejects(providerId, provisionId, policyInstance , directProblemProviderReject)
    }
  }, [providerId, policyInstance, provisionDetail.provisionId, directProblemProviderReject])


  if (requirementType == "health") {
    return (
      <HealthProvisionDetail
        {...
          {
            provisionDetail,
            setProvisionDetail,
            removeProvisionDetail,
            index,
            currency,
            prescriptions,
            setPrescriptions,
            attachments,
            healthProvisionTypes,
            policyId,
            insuree,
            policy,
            requirementType,
            showProvisionDetailCommentsModal,
            setShowProvisionDetailCommentsModal,
            getApprovedValue,
            getCoveragePercentage,
            getRefundValue,
            isEditable
          }
        }
      />
    )
  } else {
    return (
      <DentalProvisionDetail
        {...
          {
            provisionDetail,
            setProvisionDetail,
            removeProvisionDetail,
            index,
            currency,
            prescriptions,
            setPrescriptions,
            attachments,
            policyId,
            insuree,
            policy,
            requirementType,
            showProvisionDetailCommentsModal,
            setShowProvisionDetailCommentsModal,
            getApprovedValue,
            getTariffLimitFactor,
            getRefundValue,
            getReferenceValue,
            toothOptions,
            sideOptions,
            isEditable
          }
        }
      />
    )
  }
}

export default DocumentBodyProvisionDetail