import React from 'react'
import DocumentBodyProvisionDetails from './DocumentBodyProvisionDetails'
import {faFileCirclePlus} from '@fortawesome/free-solid-svg-icons'
import FormActionButton from '../../../../../../shared/FormActionButton'

const DocumentBody = (
  {
    document,
    setDocument,
    index,
    handleAddLineClick,
    prescriptions,
    setPrescriptions,
    attachments,
    healthProvisionTypes,
    policyInstance,
    directProblemProviderReject,
    policyId,
    insuree,
    policy,
    requirementType,
    toothOptions,
    sideOptions,
    isEditable
  }
) => {

  let provisionDetails = document.provisionDetails

  const removeProvisionDetail = (key) => {
    let newDocument = document
    newDocument.provisionDetails = provisionDetails.filter((item) => {
      return key !== item.systemId
    })
    setDocument(index, newDocument, false, true, 100)
  }

  const setProvisionDetail = (key, value) => {
    let newDocument = document,
      newEvaluation = false
    newDocument.provisionDetails = provisionDetails.map((item) => {
      if (key === item.systemId) {
        // Comparamos los valores para saber si cambiaron para forzar evaluación
        newEvaluation = item.canBeEvaluated
        return value
      }
      return item
    })
    setDocument(index, newDocument, false, newEvaluation)
  }

  return (
    <div className={'row document-body'}>
      <DocumentBodyProvisionDetails
        {...
          {
            provisionDetails,
            setProvisionDetail,
            removeProvisionDetail,
            prescriptions,
            setPrescriptions,
            attachments,
            healthProvisionTypes,
            policyInstance,
            directProblemProviderReject,
            policyId,
            insuree,
            policy,
            requirementType,
            toothOptions,
            sideOptions,
            isEditable
          }
        }
        providerId={document.providerId}
        currency={document.currencyType}
      />
      <div className={'col-12 form-action-buttons justify-content-center w-100 d-flex align-items-center'}>
        {isEditable && <FormActionButton
          title={'Agregar linea'}
          className={'btn--bonus'}
          onClick={handleAddLineClick}
          content={'Agregar linea'}
          icon={faFileCirclePlus}
        />}
      </div>
    </div>
  )
}

export default DocumentBody