import React, {useEffect, useState} from 'react'
import {requirementInsureeLogs} from '../../../../../routes/requirements'
import { Button, Modal } from 'react-bootstrap';
import closeAlert from '../../../../../utils/closeAlert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios'
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CustomLogsTable from './CustomLogsTable';

const LogsInsuree = (
  {
    insureeId,
    shipment,
    insuree
  }
) => {
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => {
      searchBudgetLogs();
      searchExpenseLogs();
      setShowModal(true);
    }
    const [budgetLogs, setBudgetLogs] = useState();
    const [expenseLogs, setExpenseLogs] = useState();


    const searchBudgetLogs = async () => {
      await axios({
        url: requirementInsureeLogs,
        method: 'GET',
        params: {'insuree_id': insureeId, "insurer_id": window.insurerId, "shipment": shipment, "requirement_type": 'budget'}
      })
        .then ((res) => {
          let value = res.data
          setBudgetLogs(value.aaData);
        })
        .catch(err =>{
          setBudgetLogs(false)
        })
        .finally(() => {
          closeAlert()
        })
    }
    const searchExpenseLogs = async () => {
      await axios({
        url: requirementInsureeLogs,
        method: 'GET',
        params: {'insuree_id': insureeId, "insurer_id": window.insurerId, "shipment": shipment, "requirement_type": 'expense'}
      })
        .then ((res) => {
          let value = res.data
          setExpenseLogs(value.aaData)
        })
        .catch(err =>{
          setExpenseLogs(false)
        })
        .finally(() => {
          closeAlert()
        })
    }

      return (
        <>
          <Button variant="primary" onClick={handleOpenModal} className="ml-2" title="Bitácora asegurado">
            <i className="icon-list-alt fas fa-list"></i> Ver Bitácora
          </Button>

          <Modal show={showModal} onHide={handleCloseModal} 
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered='true'
            scrollable='true'
            // dialogClassName="modal-80w modal-60h"
            contentClassName="modalLogs"
          >
            {/* <Modal.Header closeButton> */}
            <Modal.Header className='custom-header' closeButton={false}>
              <Modal.Title size="xl"><strong>Bitácora</strong> | <span className='text-secondary' >{insuree ? insuree.rut_fullname : '-' }</span></Modal.Title>
              <div className='row align-items-center'>
                <h4 className='text-secondary'><strong>presiona(esc)</strong></h4>
                <button className="bg-transparent border-0" onClick={handleCloseModal}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </Modal.Header>
            <Modal.Body bsPrefix='' className="custom-body">
              <Tabs
                className="mb-2 text-primary"
                defaultActiveKey="expenseLogs"
                id="uncontrolled-tab-example"
                tabClassName="text-primary"
              >
                <Tab eventKey="expenseLogs" title="Gastos" tabClassName="custom-title">
                  {
                  expenseLogs ? 
                    <div className='h-100' >
                      <CustomLogsTable data={expenseLogs} columns='expense'/>
                    </div>
                  : 
                    <></> 
                  }
                </Tab>
                <Tab eventKey="budgetLogs" title="Presupuestos" tabClassName="custom-title" >
                  {
                  budgetLogs ? 
                    <div className='h-100' >
                      <CustomLogsTable data={budgetLogs} columns='budget'/>
                    </div>
                  : 
                    <></> 
                  }
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </>
      ) 
} 

export default LogsInsuree